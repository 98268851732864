import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'Titulo',
    headerName: 'Titulo',
    width: 150
  },
  {
    field: 'thumbExists',
    headerName: 'Tem ficheiro de Thumbnail',
    width: 150
  },
  {
    field: 'isHTML5',
    headerName: 'É recurso HTML',
    width: 110
  },
  {
    field: 'HTMLResourceFolderIsFull',
    headerName: 'Tem pasta de ficheiros',
    width: 160
  },
  {
    field: 'FicheiroZipExists',
    headerName: 'Existe Zip',
    width: 160
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "80%",
    marginLeft: "10%"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));


export default function ProTip() {
  const [module, setModule] = useState(0);
  const [items, setItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }
  const handleSubmit = (event) => {
    console.log("e  ", module)
    setLoading(true);
    fetch("http://amzg.mobile.leya.ninja/resources/module/" + module + "/verify")
      .then(res => res.json())
      .then(
        (result) => {
          result.forEach(element => {
            element.id = element.Id
          });
          setItems(result)
          setLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error)
        }
      )
    event.preventDefault();
  }

  const classes = useStyles();
  let listItems;
  if (items.length > 0) {
    listItems = items.map((item) => {
      const url = "http://amzg.mobile.leya.ninja/resources/generatezip/" + item.id;
      return (
        <div style={{ marginLeft: '10%', marginTop: 20 }}>
          <div>id: {item.id + "; "} {item.thumbExists === false ? 'Não Tem thumbnail;     ' : "Tem Thumbnail;     "} {item.isHTML5 ? (" - É um recurso html5, tem pasta criada com conteúdo: " + item.HTMLResourceFolderIsFull + "   -   Existe ficheiro zip para sync: " + item.FicheiroZipExists) : "não é um recurso html5."}</div>
          {!item.FicheiroZipExists && item.HTMLResourceFolderIsFull ? <a href={url} target='_blank'>generate zip</a> : null}
          {!item.HTMLResourceFolderIsFull ? <div>Carregue o recurso novamente no agcm.</div> : null}
        </div>
      );
    })
  }
  return (
    loading ? <CircularProgress style={{ marginTop: 20, marginLeft: 'calc(50% - 20px)'}} /> :
      <div>
        <Paper component="form" className={classes.root}>
          <InputBase
            onKeyDown={_handleKeyDown}
            onChange={e => {
              setModule(e.target.value)
            }
            }
            className={classes.input}
            placeholder="insert module id to validate"
            inputProps={{ 'aria-label': 'insert module id' }}
          />
          <IconButton onClick={handleSubmit} className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        {
          items.length > 0 ?
            <div>
              {listItems}
            </div>


            : items ? <Typography variant="h5" style={{ marginTop: 20, marginLeft: '10%', color: 'green' }} gutterBottom>
              No Errors!
            </Typography>
              : null
        }
      </div>

  );
}
